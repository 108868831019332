<script lang="ts" setup>
import {
  useCasinoGroupsTopBarRouteComponent,
} from 'web/src/modules/casino/submodules/groups/views/CasinoGroupsTopBarRouteComponent/composables';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';

const {
  badgeKind,
  counter,
} = useCasinoGroupsTopBarRouteComponent();
</script>

<template>
  <DefaultTopBarRouteComponent v-auto-id="'CasinoGroupsTopBarRouteComponent'"
    :title="$t('WEB2_PROVIDERS')"
    :badge="counter"
    :badge-kind="badgeKind"
  />
</template>
