import type { ComputedRef } from 'vue';
import { computed, toRef } from 'vue';

import { BadgeKind } from '@components/badge';

import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';

interface CasinoGroupsTopBarRouteComponentComposable {
  badgeKind: BadgeKind;
  counter: ComputedRef<string>;
}

export default function useCasinoGroupsTopBarRouteComponent(): CasinoGroupsTopBarRouteComponentComposable {
  const badgeKind = BadgeKind.DEFAULT;
  const currentGroups = toRef(useEgsGroupsStore(), 'currentGroups');

  const counter = computed(() => (currentGroups.value.length > 0 ? String(currentGroups.value.length) : ''));

  return {
    badgeKind,
    counter,
  };
}
