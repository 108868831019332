import type { AbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';

import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';

export default class CasinoGroupsPagePrefetch extends AbstractPrefetch {
  prefetch(): AbstractPrefetchResult {
    const { loadGroups } = useEgsGroupsStore();
    void loadGroups().then();
  }
}
